import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Country } from 'react-phone-number-input';
import { useDataImportWizard } from '../../../contexts/DataImportContext';
import { DataImportFailureMessage, DataJobHeader } from '../../../models/DataImport';
import { FormAction } from '../../../models/Form';
import DataImportService from '../../../services/DataImportService';
import { indexToExcelColumn } from '../../../utils/StringUtils';
import { ActionTypeNames } from '../../form/ActionTypes';
import { Option } from '../../Option';
import CountryCodeSelect from '../CountryCodeSelect';
import CurrencyDropdown from '../CurrencyDropdown';
import { Item } from '../form-control/DropdownDefaultComponents';
import DropdownSelect from '../form-control/DropdownSelect';
import ColumnError from './ColumnError';

const UNSUPPORTED_TYPES: ActionTypeNames[] = [
  // TODO: subforms will be done in the future, but for now showing as unsupported
  'ChildFormListAction',

  'Address',
  'SelectListAction',
  'PickListAction',
  'ResourcePicklistAction',
  'MultiFileUploadAction',
  'PreSelectedForm',
  'FileOrLinkUploadAction',
  'SingleFileUploadAction',
  'DocumentReferenceAction',
];

type Props = {
  action: FormAction;
  templateQuestionTitle: string;
  mappedToIndex: number | null;

  validationErrors: DataImportFailureMessage[] | undefined;
  otherMappedColumns: number[];
  fileHeaders: DataJobHeader[];
  mapToIndex: (index: number) => void;
  updateValueMaps: (
    yesValue: string | null,
    noValue: string | null,
    naValue: string | null,
    phoneValue: string | null,
    currencyValue: string | null,
  ) => void;
  hasError: (hasError: boolean) => void;
  clearValidation: () => void;
};

const ColumnMapperRow: FC<Props> = (props) => {
  const {
    action,
    templateQuestionTitle,
    mappedToIndex,
    fileHeaders,
    mapToIndex,
    updateValueMaps,
    hasError,
    otherMappedColumns,
    validationErrors,
    clearValidation,
  } = props;
  const { wizardState } = useDataImportWizard();
  const { jobId } = wizardState;

  const [loadingColumnValues, setLoadingColumnValues] = useState(false);
  const [columnValues, setColumnValues] = useState<Option<string, string>[]>([]);

  const [yesValue, setYesValue] = useState<Option<string, string> | null>(null);
  const [noValue, setNoValue] = useState<Option<string, string> | null>(null);
  const [naValue, setNAValue] = useState<Option<string, string> | null>(null);
  const [currencyCodeValue, setCurrencyCodeValue] = useState<string | null>(null);
  const [phoneCountryCodeValue, setPhoneCountryCodeValue] = useState<Country | null>(null);

  const { t } = useTranslation(['form-builder', 'data-import-export']);
  const ranAutoAssign = useRef(false);

  const columns = useMemo<Item[]>(() => {
    return [{ id: '', text: t('data-import-export:import.mapping-step.ignore-column'), value: -1, hasDivider: true }].concat(
      fileHeaders.map((x) => ({
        id: indexToExcelColumn(x.columnIndex),
        text: x.text,
        value: x.columnIndex,
        hasDivider: false,
        disabled: otherMappedColumns.includes(x.columnIndex),
      })),
    );
  }, [fileHeaders, otherMappedColumns, t]);

  const selectedItem = useMemo<Item>(() => {
    return columns.find((x) => x.value === (mappedToIndex ?? -1))!;
  }, [columns, mappedToIndex]);

  useEffect(() => {
    if (mappedToIndex !== null || ranAutoAssign.current || UNSUPPORTED_TYPES.includes(action.type)) return;

    const match = fileHeaders.find((x) => x.text.toLowerCase() === templateQuestionTitle.toLowerCase());
    if (match) {
      ranAutoAssign.current = true;
      mapToIndex(match.columnIndex);
    }
  }, [action.type, fileHeaders, mapToIndex, mappedToIndex, templateQuestionTitle]);

  useEffect(() => {
    if (!['YesNoAction', 'TickboxAction'].includes(action.type) || (mappedToIndex ?? -1) == -1) {
      return;
    }

    setLoadingColumnValues(true);
    DataImportService.getColumnValues(jobId as string, mappedToIndex as number, wizardState.sheetIndex).then((res) => {
      setColumnValues(
        [
          {
            id: '',
            text: t('data-import-export:import.mapping-step.value-mapping.unset'),
            value: '',
            hasDivider: true,
          },
        ].concat(
          res.data.values
            .filter(Boolean) // BE sometimes sends back empty values
            .map((x) => ({ id: x, text: x, value: x, hasDivider: false })),
        ),
      );
      setLoadingColumnValues(false);
    });
  }, [action.type, jobId, mappedToIndex, t, wizardState.sheetIndex]);

  useEffect(() => {
    updateValueMaps(
      yesValue?.text || null,
      noValue?.text || null,
      naValue?.text || null,
      phoneCountryCodeValue || null,
      action.type === 'CurrencyInputAction' ? (!action.data?.allowChange ? action.data?.currencyCode : currencyCodeValue) || 'EUR' : null,
    );
  }, [
    updateValueMaps,
    naValue,
    noValue,
    yesValue,
    phoneCountryCodeValue,
    currencyCodeValue,
    action.data?.allowChange,
    action.data?.currencyCode,
    action.type,
  ]);

  const hasNaOption = useMemo(() => {
    if (action.type !== 'YesNoAction') {
      return;
    }

    return !!action.data?.enableNA;
  }, [action.data?.enableNA, action.type]);

  useEffect(() => {
    if (UNSUPPORTED_TYPES.includes(action.type) || (selectedItem?.value ?? -1) === -1) {
      hasError(false);
      return;
    }

    let valid = true;
    switch (action.type) {
      case 'YesNoAction':
        // +1 for "unset value" concatenated above
        if (hasNaOption ? columnValues.length === 3 || columnValues.length === 2 : columnValues.length === 2) {
          // if there is only 1/2 values, and we require 2/3 values, allow the blank
          valid = hasNaOption
            ? columnValues.length === 3
              ? (!!yesValue && !!noValue) || (!!yesValue && !!naValue) || (!!noValue && !!naValue)
              : !!yesValue || !!noValue || !!naValue
            : !!yesValue || !!noValue;
        }
        // otherwise require everything
        else {
          valid = !!yesValue && !!noValue && (!hasNaOption || !!naValue);
        }
        break;
      case 'TickboxAction':
        valid = !!yesValue;
        break;
      case 'CurrencyInputAction':
        valid = !!(!action.data?.allowChange ? action.data?.currencyCode || 'EUR' : currencyCodeValue);
        break;
      case 'PhoneNumberAction':
        valid = !!phoneCountryCodeValue;
    }

    hasError(!valid);
  }, [
    action.data?.allowChange,
    action.data?.currencyCode,
    action.type,
    columnValues.length,
    currencyCodeValue,
    hasError,
    hasNaOption,
    mappedToIndex,
    naValue,
    noValue,
    phoneCountryCodeValue,
    selectedItem?.value,
    yesValue,
  ]);

  const inputField = useMemo(() => {
    const mainDropdown = () => (
      <DropdownSelect
        options={columns}
        onChange={(o) => {
          clearValidation();
          mapToIndex(o.value as number);
          setYesValue(null);
          setNoValue(null);
          setNAValue(null);
        }}
        value={selectedItem}
        customListRenderer={ExcelColumnItemRenderer}
        searchable
      />
    );
    const valueDropdown = (
      selectedValue: Option<string, string> | null,
      setter: (value: Option<string, string> | null) => void,
      name: 'yes' | 'no' | 'na',
      label: string,
    ) => {
      const alreadyPicked = (name === 'yes' ? [] : [yesValue?.id])
        .concat(name === 'no' ? [] : [noValue?.id])
        .concat(name === 'na' ? [] : [naValue?.id]);
      return (
        <DropdownSelect
          options={columnValues.map((x) => ({ ...x, disabled: alreadyPicked.includes(x.id) }))}
          onChange={(o) => (o.value ? setter(o as Option<string, string>) : setter(null))}
          value={selectedValue}
          isFetching={loadingColumnValues}
          disabled={loadingColumnValues}
          label={label}
          searchable
        />
      );
    };

    if (UNSUPPORTED_TYPES.includes(action.type)) {
      return (
        <DropdownSelect
          disabled
          value={{ id: '', text: t('data-import-export:import.mapping-step.unsupported-type'), value: '' }}
          options={[]}
          onChange={() => ({})}
        />
      );
    }

    switch (action.type) {
      case 'YesNoAction':
        return (
          <div>
            {mainDropdown()}

            {selectedItem?.value !== -1 && (
              <div className="mt-4 flex items-end gap-4">
                {valueDropdown(yesValue, setYesValue, 'yes', t('data-import-export:import.mapping-step.value-mapping.yes-label'))}
                {valueDropdown(noValue, setNoValue, 'no', t('data-import-export:import.mapping-step.value-mapping.no-label'))}
                {hasNaOption && valueDropdown(naValue, setNAValue, 'na', t('data-import-export:import.mapping-step.value-mapping.na-label'))}
              </div>
            )}
          </div>
        );
      case 'TickboxAction':
        return (
          <div>
            {mainDropdown()}

            {selectedItem?.value !== -1 && (
              <div className="mt-4">
                {valueDropdown(yesValue, setYesValue, 'yes', t('data-import-export:import.mapping-step.value-mapping.ticked-label'))}
              </div>
            )}
          </div>
        );
      case 'CurrencyInputAction':
        return (
          <div>
            {mainDropdown()}

            {selectedItem?.value !== -1 && (
              <div className="mt-4">
                <div className="text-dpm-12">{t('data-import-export:import.mapping-step.value-mapping.currency-warning')}</div>
                <CurrencyDropdown
                  selectedCode={currencyCodeValue || (!action.data?.allowChange ? action.data?.currencyCode || 'EUR' : '')}
                  onChange={setCurrencyCodeValue}
                  disabled={!action.data?.allowChange}
                  label={t('data-import-export:import.mapping-step.value-mapping.currency-label')}
                />
              </div>
            )}
          </div>
        );
      case 'PhoneNumberAction':
        return (
          <div>
            {mainDropdown()}

            {selectedItem?.value !== -1 && (
              <div className="mt-8">
                <CountryCodeSelect
                  value={phoneCountryCodeValue}
                  onChange={setPhoneCountryCodeValue}
                  menuPosition="left"
                  label={t('data-import-export:import.mapping-step.value-mapping.phone-number-label')}
                />
              </div>
            )}
          </div>
        );
      default:
        return mainDropdown();
    }
  }, [
    action.data?.allowChange,
    action.data?.currencyCode,
    action.type,
    clearValidation,
    columnValues,
    columns,
    currencyCodeValue,
    hasNaOption,
    loadingColumnValues,
    mapToIndex,
    naValue,
    noValue,
    phoneCountryCodeValue,
    selectedItem,
    t,
    yesValue,
  ]);

  return (
    <div className="my-2 flex gap-4">
      <div className="flex-1 text-black">
        <div>{templateQuestionTitle}</div>
        <div className="text-dpm-14">{t(`form-builder:action-types.${action.type}`)}</div>
      </div>
      <div className="w-[350px] flex-grow-0">
        <div>{inputField}</div>
        <div>{(validationErrors?.length ?? 0) > 0 && <ColumnError errors={validationErrors as DataImportFailureMessage[]} />}</div>
      </div>
    </div>
  );
};

export default ColumnMapperRow;

const ExcelColumnItemRenderer: FC<Item> = (props) => {
  const { id, text } = props;

  // Ignore column
  if (!id) {
    return <div className="text-gray-2">{text}</div>;
  }

  return (
    <div className="flex gap-2">
      <div>
        <div className="bg-gray-5 rounded-md px-2 font-mono">{id}</div>
      </div>
      {text}
    </div>
  );
};
